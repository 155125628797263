import { environment} from './environments/environment';

const url = environment.url;
const onesignalId = environment.onesignalId;
const onesignalSafariId = environment.onesignalSafariId;
const dropboxRedirect = environment.dropboxRedirect;
const zoomRedirect = environment.zoomRedirect;
const googleRedirect = environment.googleRedirect;
const host = environment.host;
const supportNumber = environment.supportNumber;


export default {
  url,
  onesignalId,
  onesignalSafariId, 
  // stripeKey:"pk_test_51Jz4KUJhPDHR59r6pxlo7vY8ZQeda0ZXSMfPQrY2se4yiKcGuYjc8MZnWzxETaxhWeK234U1LKVB6W3IARbTaPZT00AVjjqp3S", //live not real
   stripeKey: 'pk_test_51Jz4KUJhPDHR59r6pxlo7vY8ZQeda0ZXSMfPQrY2se4yiKcGuYjc8MZnWzxETaxhWeK234U1LKVB6W3IARbTaPZT00AVjjqp3S', // dev env
  //stripeKey: 'pk_test_51KoNUbAKHpXLxTTQ8ey34o7K3Epsn9xno1KurnJeIJQxsHlM1n4yFPTliKSc6VVhyqYtKh5wA9INAWaG6gjD2O4x008UIeVUWL', // loi local
  dropboxRedirect,
  zoomRedirect,
  googleRedirect,
  host,
  supportNumber,
  api: {
    speedTest: {
      ping: `${url}/api/speed-test/ping`,
      download: `${url}/api/speed-test/download`,
      upload: `${url}/api/speed-test/upload`,
    },
    updateUserMetas: (id: string | number) => `${url}/api/users/${id}/user-metas`,
    createUser: `${url}/api/users/create/`,
    createUserAdmin: `${url}/api/users/create-admin/`,
    getProfile: `${url}/api/users/profile/`,
    updateProfile: `${url}/api/users/update/`,
    resetPassword: `${url}/api/users/reset/`,
    changePassword: `${url}/api/users/change-password/`,
    searchUser: `${url}/api/users/search/`,
    listUsers: `${url}/api/users/list/`,
    ListUsersOptimized: `${url}/api/users/list-optimized/`,
    listUserByCategory: `${url}/api/users/by-category/`,
    listUserByStudent: `${url}/api/users/by-student/`,
    member: `${url}/api/users/member/`,
    codeChangePass: `${url}/api/users/code-change-pass/`,
    admin: `${url}/api/users/admin/`,
    support: `${url}/api/users/support/`,
    getSchool: `${url}/api/users/school/`,
    login: `${url}/api/auth/app/`,
    verifyCode: `${url}/api/users/verify-code/`,
    upload: `${url}/api/upload/file/`,
    createVideo: `${url}/api/videos/create/`,
    listVideo: `${url}/api/videos/list/`,
    updateVideo: `${url}/api/videos/update/`,
    createFile: `${url}/api/file/create/`,
    listFile: `${url}/api/file/list/`,
    updateFile: `${url}/api/file/update/`,
    createFolder: `${url}/api/folder/create/`,
    listFolder: `${url}/api/folder/list/`,
    updateFolder: `${url}/api/folder/update/`,
    createQuizFolder: `${url}/api/quiz-folder/create/`,
    listQuizFolder: `${url}/api/quiz-folder/list/`,
    updateQuizFolder: `${url}/api/quiz-folder/update/`,
    createCourse: `${url}/api/courses/create/`,
    detailCourse: `${url}/api/courses/detail/`,
    getCourseVideoToken: `${url}/api/courses/video-token/`,
    updateCourse: `${url}/api/courses/update/`,
    reminderCourse: `${url}/api/courses/reminder/`,
    listCourse: `${url}/api/courses/list/`,
    listQuiz: `${url}/api/quizzes/list/`,
    detailQuiz: `${url}/api/quizzes/detail/`,
    updateQuiz: `${url}/api/quizzes/update/`,
    updatequizcount: `${url}/api/quizzes/updatequizcount`,
    createQuiz: `${url}/api/quizzes/create/`,
    importQuiz: `${url}/api/quizzes/import/`,
    listQuestion: `${url}/api/questions/list/`,
    detailQuestion: `${url}/api/questions/detail/`,
    updateQuestion: `${url}/api/questions/update/`,
    createQuestion: `${url}/api/questions/create/`,
    importQuestionExam: `${url}/api/questions/exams/`,
    importQuizQuestion: `${url}/api/questions/quiz/`,
    listQuestionExam: `${url}/api/questions/exams/`,
    deleteQuestion: `${url}/api/questions/`,
    saveQuizResults: `${url}/api/quiz-answers/create/`, //save answers from a student taking the quiz
    updateQuizResults: `${url}/api/quiz-answers/update/`,
    listQuizResults: `${url}/api/quiz-answers/list/`,
    listQuizAnswers: `${url}/api/quiz-answers/list-quiz/`,
    detailQuizResults: `${url}/api/quiz-answers/detail/`,
    detailQuizByIdResults: `${url}/api/quiz-answers/detail-by/`,
    createUserCourse: `${url}/api/user-course/create/`,
    listUserCourse: `${url}/api/user-course/list/`,
    updateUserCourse: `${url}/api/user-course/update/`,
    createGroup: `${url}/api/group/create/`,
    createGroupCourse: `${url}/api/group/create-course/`,
    getGroups: `${url}/api/group/list/`,
    allGroups: `${url}/api/group/all/`,
    getMediaGroup: `${url}/api/group/media/`,
    detailGroup: `${url}/api/group/detail/`,
    updateGroup: `${url}/api/group/update/`,
    deleteChatGroup: `${url}/api/group/`,
    sendACall: `${url}/api/group/call/`,
    createMessage: `${url}/api/message/create/`,
    updateMessage: `${url}/api/message/update/`,
    listMessage: `${url}/api/message/list/`,
    listCourseMessage: `${url}/api/message/course-list/`,
    readMessage: `${url}/api/group/read/`,
    readMessageCourse: `${url}/api/group/read-course/`,
    deleteMessage: `${url}/api/message/`,
    createCourseReport: `${url}/api/course-report/create/`,
    updateCourseReport: `${url}/api/course-report/update/`,
    getCourseReport: `${url}/api/course-report/report/`,
    getCategory: `${url}/api/category/list/`,
    getCategoryOptimized: `${url}/api/category/list-optimized/`,
    updateCategory: `${url}/api/category/update/`,
    createCategory: `${url}/api/category/create/`,
    detailCategory: `${url}/api/category/detail/`,
    duplicateCategory: `${url}/api/category/duplicate/`,
    getCategoryByUser: `${url}/api/category/by-user/`,
    getCourseByIds: `${url}/api/courses/list-ids/`,
    allCourses: `${url}/api/courses/all/`,
    allCoursesOptimized: `${url}/api/courses/all-optimized/`,
    createUserCategory: `${url}/api/user-category/create/`,
    listUserCategory: `${url}/api/user-category/list/`,
    updateUserCategory: `${url}/api/user-category/update/`,
    addMoreDay: `${url}/api/user-category/add-day/`,
    getStudentReportList: `${url}/api/course-report/student/`,
    getFreeStudent: `${url}/api/course-report/free-student/`,
    getStudentReportDetail: `${url}/api/course-report/student-report/`,
    getNotificationList: `${url}/api/notification/list/`,
    updateNotification: `${url}/api/notification/update/`, 
    createNotification: `${url}/api/notification/create/`, 
    listReminder: `${url}/api/reminder/list/`,
    createReminder: `${url}/api/reminder/create/`,
    updateReminder: `${url}/api/reminder/update/`,
    createAssignment: `${url}/api/assignment/create/`,
    createAssignmentNoti: `${url}/api/assignment/noti/`,
    getAssignmentByCourse: `${url}/api/assignment/course/`,
    listAssignment: `${url}/api/assignment/list/`,
    updateAssignment: `${url}/api/assignment/update/`,
    twilioToken: `${url}/api/call/token`,
    callMember: `${url}/api/users/call-member/`,
    callAnswer: `${url}/api/call/answer`,
    createSupportNote: `${url}/api/support-note/create/`,
    listSupportNote: `${url}/api/support-note/list/`,
    listSupportNoteUser: `${url}/api/support-note/user/`,
    updateSupportNote: `${url}/api/support-note/update/`,
    createActivity: `${url}/api/activity/create/`,
    studentCreateActivity: `${url}/api/activity/student-create/`,
    listActivity: `${url}/api/activity/list/`,
    studentListActivity: `${url}/api/activity/student-list/`,
    paymentListActivity: `${url}/api/activity/payment-list/`,
    updateActivity: `${url}/api/activity/update/`,
    listDocument: `${url}/api/document/list/`,
    createDocument: `${url}/api/document/create/`,
    updateDocument: `${url}/api/document/update/`,
    deleteDocument: `${url}/api/document/delete/`,
    listGoogle: `${url}/api/google/list/`,
    listByMail: `${url}/api/google/by-email/`,
    updateGoogle: `${url}/api/google/update/`,
    sendEmail: `${url}/api/google/send/`,
    createFaq: `${url}/api/faq/create/`,
    getFaq: `${url}/api/faq/list/`,
    searchFaq: `${url}/api/faq/search/`,
    getFaqFromUser: `${url}/api/faq/list/by-user/`,
    updateFaq: `${url}/api/faq/update/`,
    createCategoryFaq: `${url}/api/category-faq/create/`,
    getCategoryFaq: `${url}/api/category-faq/list/`,
    updateCategoryFaq: `${url}/api/category-faq/update/`,
    createAbout: `${url}/api/setting/create-about/`,
    getAbout: `${url}/api/setting/get-about/`,
    createPrivacy: `${url}/api/setting/create-privacy/`,
    getPrivacy: `${url}/api/setting/get-privacy/`,
    createTerms: `${url}/api/setting/create-terms/`,
    getTerms: `${url}/api/setting/get-terms/`,
    getSetting: `${url}/api/setting/get-setting/`,
    updateSetting: `${url}/api/setting/update-setting/`,
    getAdminSetting: `${url}/api/setting/get-admin-setting/`,
    updateAdminSetting: `${url}/api/setting/update-admin-setting/`,
    dropboxSetting: `${url}/api/setting/get-dropbox/`,
    downloadFile: `${url}/api/setting/download/`,
    connectDropbox: `${url}/api/setting/dropbox-access/`,
    connectZoom: `${url}/api/setting/zoom-access/`,
    createZoomUser: `${url}/api/setting/create-user-zoom/`,
    listZoomUser: `${url}/api/setting/list-user-zoom/`,
    joinMeetingZoom: `${url}/api/setting/join-zoom/`,
    getUserZoom: `${url}/api/setting/user-zoom/`,
    createMeetingZoom: `${url}/api/setting/create-meeting-zoom/`,
    loginGoogle: `${url}/api/setting/google/`,
    getCustomerData: `${url}/api/payment/card/`,
    checkoutCard: `${url}/api/payment/checkout/`,
    paymentHistory: `${url}/api/payment/history`,
    listHook: `${url}/api/hook/list/`,
    createHook: `${url}/api/hook/create/`,
    updateHook: `${url}/api/hook/update/`,
    studentToken: `${url}/api/users/student-token/`,
    createZoom: `${url}/api/zoom/create/`,
    detailZoom: `${url}/api/zoom/detail/`,
    importGlossary: `${url}/api/glossary/import/`,
    deleteGlossary: `${url}/api/glossary/delete/`,
    detailGlossary: `${url}/api/glossary/detail/`,
    detailGlossaryUpdate: `${url}/api/glossary/update/`,
    listGlossary: `${url}/api/glossary/list/`,

    listProduct: `${url}/api/product/list/`,
    detailProduct: `${url}/api/product/detail/`,
    updateProduct: `${url}/api/product/update/`,
    createProduct: `${url}/api/product/create/`,
    createOrder: `${url}/api/payment/create-order/`,
    checkoutOrder: `${url}/api/payment/checkout-product/`,

    listOrder: `${url}/api/order/list/`,
    detailOrder: `${url}/api/order/detail/`,
    updateOrder: `${url}/api/order/update/`,

    createBrokerage: `${url}/api/brokerages/create/`,
    listBrokerage: `${url}/api/brokerages/list/`,
    detailBrokerage: `${url}/api/brokerages/detail/`,
    updateBrokerage: `${url}/api/brokerages/update/`,

    createBrokerageMessage: `${url}/api/brokerage-messages/create/`,
    listBrokerageMessage: `${url}/api/brokerage-messages/list/`,
    detailBrokerageMessage: `${url}/api/brokerage-messages/detail/`,
    updateBrokerageMessage: `${url}/api/brokerage-messages/update/`,
    emailBrokerageMessage: `${url}/api/brokerage-messages/email/`,
    createBrokerageReply: `${url}/api/brokerage-reply/create/`,
    listBrokerageReply: `${url}/api/brokerage-reply/list/`,
    detailBrokerageReply: `${url}/api/brokerage-reply/detail/`,

    syncUserPaid: `${url}/api/users/sync-paid-user/`,
    exportSale: `${url}/api/users/export-quickbooks/`,
    logPageTime: `${url}/api/page-log/create/`,
    listLogPageTime: `${url}/api/page-log/list/`,
    listLogPageTimeAdmin: `${url}/api/page-log/list-admin/`,
    emailSyncLowerkey: `${url}/api/users/sync-email/`,

    supportTicket: `${url}/api/support-ticket/`,
    supportTicketReply: `${url}/api/support-ticket-reply/`,
    migrateOldData: `${url}/api/support-ticket/old-data/`
  },
};

//console.log("config url",url)
