import { Injectable } from '@angular/core';
import config from '../config';
import axios from 'axios';
import cookie from '../cookie';
import socket from '../socket';
import { AlertController, Platform } from '@ionic/angular';
import util from '../util';
declare const window: any;
import { detectAnyAdblocker } from 'just-detect-adblock'
import * as Sentry from '@sentry/angular-ivy';
import { MatomoTracker } from 'ngx-matomo';

@Injectable()
export class DataService {
  public currentUser: any = {};
  public token: any = '';
  public courses: any = [];
  public categories: any = []; ///contains all data
  public selectedCategory: any = {}; //contains the selected category from this.categories
  public selectedCourse: any = {}
  public quiz: any = {};
  public question: any = {};
  public questions: any = [];
  public course: any = {};
  public groups: any = [];
  public group: any = {};
  public messages: any = [];
  splitPaneState: boolean;
  public quizResult: any = {};
  public searchInput: any = '';
  public filterInput: any = '';
  public notifications: any = [];
  public loadOneSignal: any = false;
  public loaded: any = false;
  public activity: any = null;
  public userDetail: any = null;
  public adminList: any = [];
  public percentCompleted: any;
  public supportList: any = [];
  public emailDetail: any = null;
  public chatCourse: any = null;

  public appSettings: any = {};
  public applicationSettings: any = [];
  public stripe: any = null;
  public isFullScreen = false;
  public setting: any = {};
  public teacherIsLoggedInAsStudent = false;
  public adminSetting: any = {};
  public userSearchText: any = '';
  public faqCount: any = 0;
  public pendingOrders: number = 0;
  public order: any = {
    total: 0,
    products: []
  };
  public post: any = {};
  public logs: any = [];
  public logsAdmin: any = [];
  public serverUrl: any = config.url;
  public supportTicketUnread: any = 0;

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private matomo: MatomoTracker
  ) {
    this.getSettings();
    // this.stripe = window.Stripe(config.stripeKey);
    const service = this;
    axios.interceptors.response.use(undefined, function (error) {
      try {
        if (error && error.response && error.response.status === 401 && error.response.data && error.response.data?.logout) {
          service.logout();
        }
      } catch (e) {
        console.error(e);
      } finally {
        return Promise.reject(error);
      }
    });
  }

  async updateSupportTicketUnread() {
    const resp = await axios.get(`${config.api.supportTicket}list?exclude[]=data&exclude[]=status`);
    if (resp.data.unread != null) {
      this.supportTicketUnread = resp.data.unread;
      console.log('this.supportTicketUnread', this.supportTicketUnread);
    }
  }

  showDebugOptions() {
    try {
      return this.currentUser.id === 277 || this.currentUser.id === 2582;
    } catch (e) {
      return false;
    }
  }

  clearOrder() {
    this.order = {
      order: 0,
      products: []
    };
  }

  caculateOrder() {
    let total: any = 0;
    this.order.products.forEach(item => {
      if (!item.price) {
        item.price = 0;
      }
      total += parseFloat(item.price).toFixed(2);
    });
    this.order.total = total;
    this.order.address = this.currentUser.shippingAddress;
    this.order.phone = this.currentUser.phone;
  }

  setSplitPane(state: boolean) {
    if (this.platform.width() > 768) {
      this.splitPaneState = state;
    } else {
      this.splitPaneState = false;
    }
  }

  getSplitPane() {

    return this.splitPaneState;
  }

  changeTheme(theme) {
    document.body.className = '';
    if (theme === 'dark') {
      document.body.classList.add(`theme-${theme}`);
    } else
      if (theme === 'purple') {
        document.body.classList.add(`theme-${theme}`);
      }

  }
  public async getProfile() {
    try {
      const resp = await axios.get(config.api.getProfile);
      this.currentUser = resp.data;
      this.matomo.setUserId(this.currentUser.id.toString());
      if (this.currentUser.role === 'admin') {
        this.getAdminSetting();
      }

      this.fetchFaqCount()
      this.changeTheme(this.currentUser.theme);
      this.getOrderData(); //get orders

      if (!this.loadOneSignal && !window.cordova) {
        window.OneSignal = window.OneSignal || [];

        window.OneSignal.setExternalUserId(this.currentUser.id.toString());
        console.log('config.onesignalId', config.onesignalId)
        window.OneSignal.push(() => {
          window.OneSignal.init({
            appId: config.onesignalId,
            safari_web_id: config.onesignalSafariId,
            allowLocalhostAsSecureOrigin: true,
          });

          console.log('init onesignal');
        });
        this.loadOneSignal = true;
      }

      this.trySubmitPendingQuizzes();

      return resp.data;
      // console.log(window.OneSignal.setExternalUserId(3), this.currentUser.id);
    } catch (error) {
      console.log(1221212)
      // eslint-disable-next-line
      if (error == 'Error: Request failed with status code 401') {
        this.token = '';
        this.currentUser = {};
        this.matomo.resetUserId();
        window.localStorage.removeItem('token');
        axios.defaults.headers.common.Authorization = '';
      }
    }
    return;
  }


  detectAdBlock() {

    detectAnyAdblocker().then((detected) => {
      if (detected == true) {
        console.log("Ads detected", detected)
        // an adblocker is detected
        //DISABLED this.detectAdBlockAlert();
      }
    });

  }

  async detectAdBlockAlert() {
    const alert = await this.alertController.create({
      header: 'Warning, ad-blocker detected!',
      message: 'Ad-blockers may cause issues with some of the features on this site. To remove this warning please disable your ad blocker for the best experience.',
      buttons: [{
        text: 'Continue',
        handler: () => {

        }
      }
      ]
    });
    await alert.present();
  }


  public logout() {
    this.token = '';
    this.currentUser = {};
    this.matomo.resetUserId();
    window.localStorage.removeItem('token');
    axios.defaults.headers.common.Authorization = '';
    Sentry.setUser(null);
  }

  public getChatCount() {
    let count = 0;
    this.groups.forEach(item => {
      if (!item.Category) {
        count += item.readCount;
      } else {
        if (!item.unread) {
          item.unread = 0;
        }
        count += item.unread;
      }
    });

    return count;
  }




  async fetchFaqCount() {
    if (this.currentUser.role == 'user') {
      var resp = await axios.get(config.api.getFaqFromUser + "" + this.currentUser.id);
    } else {
      resp = await axios.get(config.api.getFaqFromUser);
    }
    let arr = resp.data;
    this.faqCount = arr.filter(item => !item.completed).length;

    console.log('q by stu', arr.filter(item => !item.completed))
    return this.faqCount;

  }




  public getNotificationCount() {
    //this.ticketCount()

    const count = this.notifications.filter(item => !item.read).length;

    return count;
  }




  async getMessages() {


    console.log("getting chat data...")
    const resp = await axios.get(`${config.api.listMessage}${this.group.id}`);
    this.messages = this.formatMessage(resp.data.result);
    console.log(`messages`, this.messages)
    console.log("# of private messages", this.messages.length)

  }

  formatMessage(messages) {
    const mes = messages.map((item, key) => {
      if (this.currentUser.role !== 'admin') {
        if (this.group.member.id === item.createdBy) {
          item.from = this.group.member;
        } else {
          item.from = this.currentUser;
        }
        item.createdAt = util.dateTime(item.createdAt);
        if (item.createdBy === this.currentUser.id) {
          item.isYourMessage = true;
        }
        if (!key) {
          item.showUserData = true;
        } else {
          if (item.createdBy !== messages[key - 1].createdBy) {
            item.showUserData = true;
          }
        }
      } else {
        const index = this.group.members.findIndex((user) => user.id === item.createdBy);
        item.from = this.group.members[index];
        item.showUserData = true;
      }
      return item;
    });

    return mes;
  }



  playAudio() {
    let audio = new Audio();
    audio.src = "./assets/sounds/new-message.mp3";
    audio.load();
    audio.play();
  }



  simpleSearch($event, dataSource, field) {
    const input = typeof $event === 'string' ? $event : $event.target.value;
    //console.log("searching through datasource", dataSource)
    dataSource.forEach(item => {
      item.hidden = String(item[field]).toLowerCase().indexOf(input.toLowerCase()) > -1;
      item.hidden = !item.hidden;
    });

    if (input == "") {
      dataSource.forEach(item => {
        item.hidden = false;
      });

    }
  }



  simpleFilter(dataSource, field) {
    console.log(dataSource)
    dataSource.forEach(item => {
      item.hidden = item[field].toLowerCase().indexOf(this.filterInput) > -1;
      item.hidden = !item.hidden;
    });

    if (this.filterInput == "") {
      dataSource.forEach(item => {
        item.hidden = false;
      });

    }

  }


  async getSettings() {
    console.log("default app settings loaded")
    this.appSettings = {
      hideProfileNavigation: true,
      hideLessonsNavigation: true,
      EmailNavigation: true
    }

    this.applicationSettings = [
      {//0
        "name": "hideProfileNavigation",
        "enabled": false,
        "prettyName": "Show Profile picture in navigation"
      },
      {//1
        "name": "hideLessonsNavigation",
        "enabled": false,
        "prettyName": "Lessons"
      },
      {//2
        "name": "EmailNavigation",
        "enabled": false,
        "prettyName": "Email"
      },
      {//3
        "name": "checkoutNav",
        "enabled": false,
        "prettyName": "Old cart (depreciated, disabled)"
      },
      {//4
        "name": "showReadinessGuage",
        "enabled": false,
        "prettyName": "Enable Readiness gauge"
      },
      {//5
        "name": "productEditor",
        "enabled": false,
        "prettyName": "Product Editor"
      },
      {//6
        "name": "gobcShop",
        "enabled": false,
        "prettyName": "Enable In-App Purchasing"
      },
      {//7
        "name": "underConstruction",
        "enabled": false,
        "prettyName": "Popup user message!!!"
      },
      {//8
        "name": "userAccounts",
        "enabled": false,
        "prettyName": "User Accounts"
      },
      {//9
        "name": "orderManagement",
        "enabled": false,
        "prettyName": "Order Management"
      },
      {//10
        "name": "supportTickets",
        "enabled": false,
        "prettyName": "Support Tickets"
      },
      {//11
        "name": "zoom",
        "enabled": false,
        "prettyName": "Create/Join Zoom"
      },
      {//12
        "name": "classroomchat",
        "enabled": false,
        "prettyName": "Classroom Chat"
      },
      {//13
        "name": "activities",
        "enabled": false,
        "prettyName": "Activities"
      },
      {//14
        "name": "dropbox",
        "enabled": false,
        "prettyName": "Dropbox"
      },
      {//15
        "name": "adminsupport",
        "enabled": false,
        "prettyName": "Admin Support"
      },
      {//16
        "name": "courseeditor",
        "enabled": false,
        "prettyName": "Course Editor"
      },
      {//17
        "name": "managers",
        "enabled": false,
        "prettyName": "Quiz, Video, File Managers"
      },
      {//18
        "name": "glossary",
        "enabled": false,
        "prettyName": "Glossary"
      },
      {//19
        "name": "chathistory",
        "enabled": false,
        "prettyName": "Chat History"
      },
      {//20
        "name": "notifications",
        "enabled": false,
        "prettyName": "Notifications"
      },
      {//21
        "name": "assignments",
        "enabled": false,
        "prettyName": "Assignment Inbox"
      },
      {//22
        "name": "brokerage",
        "enabled": false,
        "prettyName": "Brokerage Manager"
      },
      {//23
        "name": "brokerageviewer",
        "enabled": false,
        "prettyName": "Brokerage Viewer"
      }
    ];

  }


  async getCategory() {
    this.categories = [];
    this.loaded = false;
    const colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'dark'];
    const url = config.api.getCategoryOptimized;
    const resp = await axios.get(url);
    //this.categories = resp.data; //moved to dataService
    this.categories = resp.data;



    localStorage.setItem("categoriesCached", JSON.stringify(this.categories))
    console.log('this.dataService.categories', this.categories);
    this.loaded = true;
  }



  async getCourses() {
    console.log("getting courses")
    this.loaded = false;
    this.courses = [];
    const colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'dark'];

    const resp = await axios.get(config.api.listCourse);


    this.courses = resp.data.map((item, key) => {
      item.quizTotal = 0;
      item.lessonTotal = 0;
      item.videoTotal = 0;

      item.steps.forEach(step => {
        if (step.type == 'quiz') { item.quizTotal++ }
        if (step.type == 'lesson') { item.lessonTotal++ }
        if (step.type == 'video') { item.videoTotal++ }
      });

      ///should join the enrolled users so we can display a count

      item.bagde = colors[Math.floor(Math.random() * (colors.length - 0 + 1) + 0)];
      return item;
    });
    console.log('this.dataService.courses', this.courses);
    this.loaded = true;
  }

  async getAdminSetting() {
    const res = await axios.get(config.api.getAdminSetting);
    this.adminSetting = res.data;

  }



  async getOrderData() {
    const resp = await axios.get(config.api.listOrder, {
      params: {
        orderStatus: 'pending'
      }
    })
    this.pendingOrders = resp.data.length;
    console.log('resp', resp.data)
  }

  async logTimePage(page, action) {
    await axios.post(config.api.logPageTime, {
      page,
      action
    });
  }

  async getLogs(userId) {
    const resp = await axios.get(config.api.listLogPageTime, {
      params: {
        userId,
      }
    });
    resp.data.forEach(item => {
      if (!item.time) {
        item.time = 0;
      }
      const second = item.time;
      item.timeShow = util.fancyTimeFormat(second);
    });
    this.logs = resp.data;
    console.log('this.logs', this.logs);
  }



  async getLogsAdmin() {
    const resp = await axios.get(config.api.listLogPageTimeAdmin, {
    });
    resp.data.forEach(item => {
      if (!item.time) {
        item.time = 0;
      }
      const second = item.time;
      item.timeShow = util.fancyTimeFormat(second);
    });
    this.logsAdmin = resp.data;
    console.log('this.logs 1', this.logsAdmin);
  }


  /**
   * Quizzes
   */

  saveQuizToLocalStorage(obj) {
    if (!obj) {
      return;
    }
    try {

      let failedArray = JSON.parse(window.localStorage.getItem('quiz-submit-failed') ?? '[]');
      if (!Array.isArray(failedArray)) {
        failedArray = [];
      }
      failedArray.push(obj);
      window.localStorage.setItem('quiz-submit-failed', JSON.stringify(failedArray));

      console.log('Saved quiz to local storage array:', obj)
    } catch (e) {
      console.error('Failed to save quiz to local storage array. Instead, saving to single field.', e);
      window.localStorage.setItem('quiz-submit-failed-temp', JSON.stringify(obj));
      console.log('Saved quiz to local storage single field:', obj)
    }
  }

  /**
   * @param {boolean} preserveUnsuccessful If true, will keep the failed submissions in the local storage for later retry.
   */
  async trySubmitPendingQuizzes(preserveUnsuccessful = false) {

    const makeRequest = async (obj) => {

      console.log('Submitting quiz:', obj);

      if (!obj || !obj.quizAnswerId) {
        console.error('Invalid quiz object', obj);
        return true;
      }

      try {
        const resp = await axios.put(config.api.updateQuizResults + obj.quizAnswerId, {
          ...obj.payload,
          endQuiz: true
        });
        console.log('Quiz Submitted Successfully:', resp);
        return true;
      } catch (e) {
        console.error('Failed to submit quiz:', e);
        return false;
      }
    }

    const failedArray = JSON.parse(window.localStorage.getItem('quiz-submit-failed') ?? '[]');
    const preservedArray = [];

    if (!Array.isArray(failedArray)) {
      console.error('Invalid quiz array', failedArray);
    } else {
      for (let i = 0; i < failedArray.length; i++) {
        const obj = failedArray[i];
        if (!await makeRequest(obj) && preserveUnsuccessful) {
          preservedArray.push(obj);
        }
      }
    }

    window.localStorage.setItem('quiz-submit-failed', JSON.stringify(preservedArray));

    const temp = JSON.parse(window.localStorage.getItem('quiz-submit-failed-temp') ?? '{}');

    if (temp.quizAnswerId) {
      if (await makeRequest(temp) || !preserveUnsuccessful) {
        window.localStorage.removeItem('quiz-submit-failed-temp');
      }
    }
  }
}
