import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import config from '../../config';
import { DataService } from '../app.service';
import axios from 'axios';
import { ProductDetailPage } from '../modals/product-detail/product-detail.page';
import * as toastr from 'toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-products-checkout',
  templateUrl: './user-products-checkout.page.html',
  styleUrls: ['./user-products-checkout.page.scss'],
})
export class UserProductsCheckoutPage implements OnInit {

  public url: any = config.url;
  public status: any = 'check';

  public name: any = '';
  public email: any = '';
  public phone: any = '';
  public card: any = null;
  public isCheckout: any = false;
  public isName: any = false;
  public isEmail: any = false;
  public isPhone: any = false;
  public textError: any = '';
  public cardData: any = null;
  public isLoading: any = true;


  constructor(public dataService: DataService,public modalController: ModalController, public router: Router) {
    this.name = this.dataService.currentUser.fullName;
    this.email = this.dataService.currentUser.email;
    this.phone = this.dataService.currentUser.phone;
    this.getCard();
  }


  ngOnInit() {
    console.log(this.dataService.categories)
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }


  async getCard() {
    const resp = await axios.get(config.api.getCustomerData);
    this.cardData = resp.data;
    this.isLoading = false;
    setTimeout(() => {
      this.initFormStripe();
    }, 1000);
    console.log(resp);
  }
  initFormStripe() {
    const elements = this.dataService.stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: '#222',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#222'
        },
        backgroundColor: '#fff',
        height: '100px',
        borderColor: '#ddd',
      },
      invalid: {
        color: '#222',
        iconColor: '#222'
      }
    };

    // Create an instance of the card Element.
    this.card = elements.create('card', { style });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element');
  }

  changeCard () {
    this.cardData = null;
  }

  async checkoutCard() {
    console.log(22232132132)
    const self = this;
    console.log(this.isCheckout);
 
    let partialPaymentNumber = 1;
    this.dataService.order.products.forEach(p => {
      if(p.numberOfPartialPayments>1){
        partialPaymentNumber = p.numberOfPartialPayments; 
      }
    });


    const respOrder = await axios.post(config.api.createOrder, {
      address: this.dataService.order.address,
      phone: this.dataService.order.phone,
      desc: this.dataService.order.desc,
      partialPaymentNumber: partialPaymentNumber,
      currentPartialPayment: 1,
      productIds: this.dataService.order.products.map(item => item.id)
    });

    console.log('respOrder', respOrder);
    const dataPayload: any = {
      total: self.dataService.order.total,
      id: respOrder.data.id,
      name: self.name,
      phone: self.phone,
      email: self.email,
    };

    const handlePayment = async () => {
      const resp = await axios.post(config.api.checkoutOrder, dataPayload);
      console.log('resp', resp);
      if (resp.data.message === 'success') { 
        this.dataService.clearOrder();
        this.cancel();
        toastr.success(`Payment was successful.`);
        this.router.navigateByUrl('/profile'); 
        this.modalController.dismiss({message: 'done'}, 'cancel');
      }
    };

    if (self.cardData) {
      if (this.isCheckout) {
        return;
      };
      this.textError = '';
      self.isCheckout = true;
      handlePayment();
    } else {
      if (!this.name) {
        this.isName = true;
        return;
      }
      if (!this.email) {
        this.isEmail = true;
        return;
      }
      if (!this.phone) {
        this.isPhone = true;
        return;
      }
      if (this.isCheckout) return;
      this.textError = '';
      this.isCheckout = true;
      this.isName = false;
      this.isEmail = false;
      this.isPhone = false;
      this.dataService.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card
        })
        .then( (result) => {
          console.log('result', result)
          if (result.error) {
            self.isCheckout = false;
            self.textError = result.error.message;
          } else if (result.paymentMethod) {
            dataPayload.payment_method = result.paymentMethod.id;
            handlePayment();
          }
        });
    }

  } 
  
}
