import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'academy',
    loadChildren: () => import('./academy/academy.module').then( m => m.AcademyPageModule)
  },
  {
    path: 'academy/:id',
    loadChildren: () => import('./course/course.module').then( m => m.CoursePageModule)
  },
  {
    path: 'academy/:id/:step',
    loadChildren: () => import('./course/course.module').then( m => m.CoursePageModule)
  },
  {
    path: 'academy/:id/:step/:category',
    loadChildren: () => import('./course/course.module').then( m => m.CoursePageModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'quiz/:id',
    loadChildren: () => import('./quiz-detail/quiz-detail.module').then( m => m.QuizDetailPageModule)
  },
  {
    path: 'user-academy/:id',
    loadChildren: () => import('./user/user-academy/user-academy.module').then( m => m.UserAcademyPageModule)
  },
  {
    path: 'user-academy-detail/:id',
    loadChildren: () => import('./user/user-course/user-course.module').then( m => m.UserCoursePageModule)
  },
  {
    path: 'user-academy-detail/:id/:step',
    loadChildren: () => import('./user/user-course/user-course.module').then( m => m.UserCoursePageModule)
  },
  {
    path: 'user-quiz/:id',
    loadChildren: () => import('./user/user-quiz/user-quiz.module').then( m => m.UserQuizPageModule)
  },
  {
    path: 'user-report',
    loadChildren: () => import('./user/user-report/user-report.module').then( m => m.UserReportPageModule)
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./user/user-dashboard/user-dashboard.module').then( m => m.UserDashboardPageModule)
  },
  {
    path: 'user/payment-history',
    loadChildren: () => import('./user/user-payment-history/user-payment-history.module').then( m => m.UserPaymentHistoryPageModule)
  },
  {
    path: 'user-report/:id',
    loadChildren: () => import('./user/user-report-detail/user-report-detail.module').then( m => m.UserReportDetailPageModule)
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./admin/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'admin/support',
    loadChildren: () => import('./admin/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'admin/activities',
    loadChildren: () => import('./admin/activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'admin/chats',
    loadChildren: () => import('./admin/chats-manager/chats-manager.module').then( m => m.ChatsManagerPageModule)
  },
  {
    path: 'admin/courses',
    loadChildren: () => import('./category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'admin/files',
    loadChildren: () => import('./file/file.module').then( m => m.FilePageModule)
  },
  {
    path: 'zoom',
    loadChildren: () => import('./zoom/zoom.module').then( m => m.ZoomPageModule)
  },
  {
    path: 'auth-zoom',
    loadChildren: () => import('./auth-zoom/auth-zoom.module').then( m => m.AuthZoomPageModule)
  },
  {
    path: 'auth-google',
    loadChildren: () => import('./auth-google/auth-google.module').then( m => m.AuthGooglePageModule)
  },
  {
    path: 'admin/courses/:id',
    loadChildren: () => import('./category-detail/category-detail.module').then( m => m.CategoryDetailPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'course-report/:id',
    loadChildren: () => import('./course-report/course-report.module').then( m => m.CourseReportPageModule)
  },
  {
    path: 'user-category',
    loadChildren: () => import('./user/user-category/user-category.module').then( m => m.UserCategoryPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'dropbox',
    loadChildren: () => import('./dropbox/dropbox.module').then( m => m.DropboxPageModule)
  },
  {
    path: 'dropbox-folder',
    loadChildren: () => import('./dropbox-folder/dropbox-folder.module').then( m => m.DropboxFolderPageModule)
  },
  {
    path: 'user-assignment',
    loadChildren: () => import('./user/user-assignment/user-assignment.module').then( m => m.UUserAssignmentPageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./user/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'user-checkout',
    loadChildren: () => import('./user/user-checkout/user-checkout.module').then( m => m.UserCheckoutPageModule)
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./user/user-dashboard/user-dashboard.module').then( m => m.UserDashboardPageModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./gmail/gmail.module').then( m => m.GmailPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('./exam/exam.module').then( m => m.ExamPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./modals/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'glossary',
    loadChildren: () => import('./glossary/glossary.module').then( m => m.GlossaryPageModule)
  },
  {
    path: 'glossary/:id',
    loadChildren: () => import('./glossary-detail/glossary-detail.module').then( m => m.GlossaryDetailPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'user-products',
    loadChildren: () => import('./user-products/user-products.module').then( m => m.UserProductsPageModule)
  },
  {
    path: 'brokerage',
    loadChildren: () => import('./broker/brokerage/brokerage.module').then( m => m.BrokeragePageModule)
  },
  {
    path: 'brokerage-user',
    loadChildren: () => import('./broker/brokerage-user/brokerage-user.module').then( m => m.BrokerageUserPageModule)
  },
  {
    path: 'brokerage-message',
    loadChildren: () => import('./broker/brokerage-message/brokerage-message.module').then( m => m.BrokerageMessagePageModule)
  },
  {
    path: 'support-tickets',
    loadChildren: () => import('./admin/support-tickets/support-tickets.module').then( m => m.SupportTicketsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
