<ion-header>
  <ion-toolbar color="success">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Back</ion-button>
    </ion-buttons> 

    <ion-buttons *ngIf="status!=='payment'" slot="end">
      <ion-button  (click)=" this.dataService.clearOrder(); this.modalController.dismiss();">Clear Cart</ion-button>
    </ion-buttons> 
 

    
    
    <ion-buttons *ngIf="status!=='payment'" slot="end">
      <ion-button  (click)="status = 'payment'">Proceed to Checkout</ion-button>
    </ion-buttons> 
    
 

  </ion-toolbar>
</ion-header>

<ion-content>



  <div class="detail-order" [hidden]="status !== 'check'">
    <ion-list>
      <ion-item-divider>Products added to cart</ion-item-divider>
      <ion-item  *ngFor="let p of dataService.order.products; let i = index;">
        <ion-thumbnail slot="start">
          <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/thumbnail.svg" *ngIf="!p.thumbnailImage" />
          <img [src]="url + '/' + p.thumbnailImage" *ngIf="p.thumbnailImage"/>
        </ion-thumbnail>
        <ion-label>
          <h2>{{p.productName}}</h2>
          <p>Full Price: ${{p.price}} </p> 
            <p *ngIf="p.numberOfPartialPayments>1">This is a partial payment: 1 of {{p.numberOfPartialPayments}}</p>
            <p *ngIf="p.numberOfPartialPayments>1">First Payment: <b>${{p.price / p.numberOfPartialPayments}}</b></p>
         
        </ion-label>
      </ion-item> 
    </ion-list>
 
    <ion-list>
      <ion-item-divider>Shipping Information</ion-item-divider>
    
    <ion-item>
      <ion-label position="stacked">Shipping Address</ion-label>
      <ion-input type="text"  [(ngModel)]="dataService.order.address"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Phone number</ion-label>
      <ion-input type="tel"  [(ngModel)]="dataService.order.phone"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Special Order Notes or Requests:</ion-label>
      <ion-input type="text"  [(ngModel)]="dataService.order.desc"></ion-input>
    </ion-item> 
    <ion-item detail="true" button size="large" color="success"  (click)="status = 'payment'">
       Proceed to Checkout 
       <ion-label slot="end">${{dataService.order.total | number : '1.0-2'}}</ion-label>
      </ion-item> 
  </ion-list>
  </div>
  <div class="order-pay ion-text-center" [hidden]="status !== 'payment'">
    <div style="text-align: center;"><img src="assets/stripe.png"/></div>

    <div class="ion-padding custom-skeleton" *ngIf="isLoading">
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </div>

    
    <div class="padding" *ngIf="!isLoading">
      <div [hidden]="cardData">
        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input  placeholder="Jane Doe" [(ngModel)]="name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Email</ion-label>
          <ion-input type="email" placeholder="janedoe@gmail.com" [(ngModel)]="email"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Phone</ion-label>
          <ion-input type="tel" placeholder="(941) 555-0123" [(ngModel)]="phone"></ion-input>
        </ion-item>

        <div class="row">
          <div id="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
        </div>
      </div>

      <div class="card-data" *ngIf="cardData">
        <ion-card>
          <ion-card-header>
            <ion-card-title><span class="card-type">{{cardData.brand}}</span> - **** **** **** {{cardData.last4}}</ion-card-title>
            <ion-card-subtitle><span class="card-name">{{dataService.currentUser.fullname}}</span></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <a (click)="changeCard()" class="add-other-card">Add other card</a>
      </div>

      <div id="card-errors" *ngIf="textError">{{textError}}</div>

    </div>
    <ion-button size="large" [disabled]="this.isCheckout" color="success" expand="block" (click)="checkoutCard()">
      Complete Payment of ${{dataService.order.total | number : '1.0-2'}}
      <ion-spinner *ngIf="isCheckout" class="spinner-login">
      </ion-spinner>
    </ion-button>
  </div>
  

  

  


 


</ion-content>
