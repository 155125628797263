import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatCategoryComponent } from './chat-category.component';
import { AvatarComponentModule } from '../avatar/avatar.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, AvatarComponentModule],
  declarations: [ChatCategoryComponent],
  exports: [ChatCategoryComponent]
})
export class ChatCategoryComponentModule {}
