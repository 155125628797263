import { Component, OnInit, Input } from '@angular/core'; 
import config from '../../../config';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() data: any;
  @Input() type: string;

  constructor() {
  }

  ngOnInit() {
    if (this.data.avatar) {
      const isHTTP = this.data.avatar.indexOf("http") !== -1;
      if (!isHTTP) {
        this.data.avatar = config.url + this.data.avatar;
      }
    }
  }

}
