import { Injectable } from '@angular/core';
import { DataService } from '../app.service';
import axios from 'axios';
import config from '../../config';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import socket from '../../socket';
import util from  '../../util';
import emitter from '../../event';

import * as toastr from 'toastr';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public isInitSocket: any = false;
  public page: any = 'signin';
  public user: any = {};
  public formError: any = '';
  public loadingLogin: any = false;
  public loadingPage: any = true;
  public schools: any = [];
  public userId: any = '';
  public show2FA: any = false;
  public code: any = '';
  public isToggleMenu: any = false;
  public codeVerified: boolean = false;

  constructor(
    public dataService: DataService,
    public router: Router,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private route: ActivatedRoute
  ) { }


  
  async submitLogin() {
    this.formError = '';
    console.log(this.user)
    if (!this.user.email || !this.user.password) {
      return this.formError = 'The user or password field is empty.';
    }
    this.loadingLogin = true;
    
    try {
      const resp = await axios.post(config.api.login, this.user);
      if (resp.data.error && resp.data.error.cellphone) {
        this.formError = 'Your phone number is not correct, please contact support for help';
        this.loadingLogin = false;
        return;
      }
      if (resp.data.twoFactor) {
          this.show2FA = true;
          this.userId = resp.data.userId;
      } else {
        const token = resp.data.token;
        console.log('token 1', token);
        console.log('token 2', resp.data.user.lastToken);
        window.localStorage.setItem('token', token);
        await this.handleToken();
        ///check if user is already logged in
        // if (resp.data.user.lastToken && token !== resp.data.user.lastToken && resp.data.user.role == 'user') {
        //   this.logout();
        //   this.tryAgainLogin();
        // } else {
        //   // alert('logged in')
        //   await this.handleToken();
        // }
      }

    } catch(e) {
      this.formError = 'Sorry, you have entered an incorrect email and/or password. Emails and Passwords are Case-Sensitive. '; 

      if(e.message){this.formError = this.formError+" Error Info: "+e.message}
      console.log("Error",e.message)

    }
    this.loadingLogin = false;
  } 






  async submitSignup() {
    this.formError = '';
    console.log(this.user)
    if (!this.user.email || !this.user.password) {
      return this.formError = 'Sorry, you have entered an incorrect email and/or password. Emails and Passwords are Case-Sensitive.'; 
    }
    if (!this.user.fullName) {
      return this.formError = 'Invalid full name';
    } else {
      this.loadingLogin = true;
      try {
        this.user.role = 'user'
        const resp = await axios.post(config.api.createUser, this.user);
        const token = resp.data.token;
        window.localStorage.setItem('token', token);
        
        this.show2FA = true;//////2 factor authentication enabled on signup success
        await this.handleToken();
      } catch {
        this.formError = 'Email already used!';
      }
      this.loadingLogin = false;
    }
  }





  




  
  async tryAgainLogin() {
  

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Already signed in',
      message: 'You are already signed into another device. Please logout of the other device and try again. ',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.dataService.logout();
          }
        }, {
          text: 'Okay',
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
            this.dataService.logout();
          }
        }
      ],
      backdropDismiss: false // <- Here! :)
    });

    await alert.present();
  }






  
  async verifyCode() {
    this.loadingLogin = true;
    try {
      if (!this.userId || !this.code) {
        this.formError = 'Invalid Code';
        return;
      }
      const resp = await axios.post(config.api.verifyCode, {
        userId: this.userId,
        code: this.code
      });
      const token = resp.data.token;
      console.log('token 1111', token);
      window.localStorage.setItem('token', token); ///this is overwriting the other token...might break things...should have a unique name
      this.codeVerified=true;
     
       
      await this.handleToken();



    } catch {
      this.formError = 'Invalid Code';
    }
    this.loadingLogin = false;
  }







  
  async logout() { 
    const payload: any = {};

    payload.lastToken = null; ///this removes the last token for the user so they can login using a different device. only 1 device per user at a time
    await axios.put(config.api.updateProfile, payload).then(() => { 
      localStorage.setItem('popState','notshown')
      this.router.navigate([`/`]);

      this.dataService.logout();

    }
      
    );

  }
  


      
async checkIfAccountDeleted() { 
  this.dataService.logout();
  const alert = await this.alertController.create({
    header: 'Your account has expired!',
    message: 'To continue using this service please upgrade your account.', 
    backdropDismiss:false,
    buttons: [ {
        text: 'Okay',
        handler: () => {
          console.log('Confirm Okay'); 
        }
      }
    ]
  });
 
   
      await alert.present(); 

}
 



  
  async handleToken() {
    const token = window.localStorage.getItem('token');
    
    console.log('token', token);
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      await this.dataService.getProfile();
      socket.init(() => {
        try {
          console.log('init socket')
          socket.getSocket().emit('join-user', `room-${this.dataService.currentUser.id}`);
          this.handleSocket();
          this.getGroups();
          this.getNotifications();
          this.getSupport();
          this.dataService.updateSupportTicketUnread();
        } catch(e) {
          console.error('Error joining room:', e);
        }

      }, this.dataService.currentUser.id);

      Sentry.setUser({ id: this.dataService.currentUser.id, username: this.dataService.currentUser.fullName, email: this.dataService.currentUser.email });
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'User token set',
        level: 'info',
        data: this.dataService.currentUser,
      })

      const tokenTeacher = window.localStorage.getItem('tokenTeacher');
      if (tokenTeacher) {
        this.dataService.teacherIsLoggedInAsStudent = true
      }
      console.log('this.dataService.currentUser.id', this.dataService.currentUser)

      if (!this.dataService.currentUser.active) {
        Sentry.captureEvent({
          message: 'User is not active',
          level: 'error',
          extra: this.dataService.currentUser,
        })
        this.logout();
        // const alert = await this.alertController.create({
        //   header: 'Error',
        //   message: 'Failed to access the application.',
        //   buttons: ['OK'],
        // });
        // await alert.present();
        toastr.error("Logged out.");
      }
      
  if(this.dataService.currentUser.deleted==true){
    this.checkIfAccountDeleted();
    }

      

      if (this.dataService.currentUser.id) {
        if (window.location.pathname !== '/dropbox' && window.location.pathname !== '/auth-zoom'
          && window.location.pathname !== '/auth-google') {
          if (this.router.url === '/') {
            const { role } = this.dataService.currentUser;
            if (  role === 'teacher' || role === 'school') {
              this.router.navigate([`/user-category`]);
            } else if(role === 'support') {
              this.router.navigate([`/support-tickets`]);
            }  else if(role === 'user') {
              if (!tokenTeacher) {
                this.router.navigate([`/user-category`]);
              } else {
                this.router.navigate([`/user-category`]);
              }
            } else {
              this.router.navigate([`/profile`]);
            }

            
            
            this.dataService.detectAdBlock();


          }
        }
      }
      this.getSetting();
        if(this.codeVerified==true){
         // alert("Code Accepted, welcome!");
         console.log("code accepted")
          this.codeVerified=false;
        }
      this.loadingPage = false;
    } else {
      this.loadingPage = false;
    }
    this.getSchool();

  }





  
  handleSocket() {
    if (this.isInitSocket) {
      return;
    }
    this.isInitSocket = true;
    socket.subscribe(`socket-event`, (doc) => {
      console.log('doc', doc)
      this.handleNewGroup(doc);
      this.handleChatList(doc);
      this.handleGroupMessage(doc);
      this.handleDeleteMessage(doc);
      this.handleNewNotification(doc)
    });
  }

  handleNewNotification(doc) {
    if (doc.type === 'new-notification') {
      this.getNotifications();
      if (doc.supportTicketId) {
        this.dataService.updateSupportTicketUnread();
      }
    }
  }

  // start handle group chat
  handleNewGroup(doc) {
    if (doc.type === 'group') {
      const group = doc.data;
      group.readCount = 0;
      if (!group.group) {
        let index = 0;
        if (group.users[index].id === this.dataService.currentUser.id) {
          index = 1;
          group.lastMessage = '...';
        }
        group.member = group.users[index];
      } else {
        group.member = {
          id: 1,
          bgColor: group.color,
          fullName: group.name
        };
        group.readCount = 1;
      }

      group.lastMessageTime = 'now';
      this.dataService.groups.unshift(group);
      if (group.lastMessage === '...') {
        this.dataService.group = this.dataService.groups[0];
      }
      emitter.emit('create-group', group);
    }
  }
  handleChatList(doc) {
    if (doc.type === 'message') {
      const message = doc.data;
      const index = this.dataService.groups.findIndex(item => item.id === message.group);
      if (index !== -1) {
        this.dataService.groups[index].lastMessage = message.lastMessage;
        this.dataService.groups[index].lastMessageTime = util.dateTime(message.createdAt);
        const indexRead = this.dataService.groups[index].read.findIndex(mem => mem.memberId === message.read.memberId);
        if (indexRead !== -1) {
          this.dataService.groups[index].read[indexRead] = message.read;
          this.dataService.groups[index].readCount = message.read.read;
          this.dataService.playAudio();
        }
        console.log('this.dataService.groups[index].lastMessage', this.dataService.groups[index])
      }
    }
  }

  handleGroupMessage(doc) {
    if (doc.type === 'message' && doc.data.createdBy !== this.dataService.currentUser.id) {
      emitter.emit('message', {
        type: 'message',
        data: doc.data
      });
    }
    if (doc.type === 'message-course' && doc.data.createdBy !== this.dataService.currentUser.id) {
      const index = this.dataService.groups.findIndex((item) =>  item.Category && item.Category.id === doc.data.course)
      if (index !== -1) {
        this.dataService.groups[index].unread = doc.data.unread;
      }
    }
  }

  handleDeleteMessage(doc) {
    if (doc.type === 'delete-message') {
      emitter.emit('message', {
        type: 'delete-message',
        data: doc.data
      });
    }
  }
  // end handle group chat








  
  async getSchool() {
    const resp = await axios.get(config.api.getSchool);
    this.schools = resp.data;
  }

  async getNotifications() {
    try {
      const resp = await axios.get(config.api.getNotificationList);
      this.dataService.notifications = resp.data;
      console.log('this.dataService.notifications', this.dataService.notifications)
    } catch(e) {
      console.error('Error fetching notifications:', e);
    }
  }


  async getSupport() {
    if (this.dataService.currentUser.role === 'admin') {
      const res = await axios.get(config.api.support);
      this.dataService.supportList = res.data;
    }
  }

  async getGroups() {
    const res = await axios.get(config.api.getGroups);
    const groups = res.data.map(item => {
      if (item.Category) {
        item.courseData = {
          fullName: item.Category.title,
          color: item.color
        };
      } else {
        const index = item.read.findIndex((mem) => mem.memberId === this.dataService.currentUser.id);
        if (index !== -1) {
          item.readCount = item.read[index].read;
        }
      }
      return item;
    });
    this.dataService.groups = groups;
  }

  async getSetting() {
    console.log("Getting the settings, please wait...")
    const res = await axios.get(config.api.getSetting);
    this.dataService.setting = res.data;
    this.dataService.setting.loaded = true;
    console.log('setting', this.dataService.setting)
    if (this.dataService.setting.metaTitle) {
      document.title = this.dataService.setting.metaTitle;
    }
    if(res.data.applicationSettings && res.data.applicationSettings.length>0){
      //this will set the role settings based on the user role and defined application settings from the database
      this.dataService.setting.applicationSettings.forEach(roleSetting => { 
        
        if(roleSetting.role==this.dataService.currentUser.role){
          console.log(roleSetting.role+" Setting loaded for "+this.dataService.currentUser.role)
          this.dataService.applicationSettings = roleSetting.settings;

          if(this.dataService.applicationSettings[7].enabled==true){ //under construction
            this.underConstruction();
            }

          return;
        }
        
      });
       // this.dataService.applicationSettings = res.data.applicationSettings[0].settings; //this will default load the first entry
    }else{
      if(this.dataService.currentUser.role=="admin"){alert("No permissions profile loaded. Using default settings. Please login as admin to update the user permission roles.")}
      this.dataService.setting.applicationSettings=[{
        "role": "admin",
        "hidden": true,
        "settings":  this.dataService.applicationSettings 
        
      }]; 
      //this.dataService.applicationSettings = this.dataService.setting.applicationSettings[0];
    }


  }



  async underConstruction() {
    const alert = await this.alertController.create({
      header: "Notification Message:", 
      message: this.dataService.applicationSettings[7].prettyName,
      buttons: ['OK'],
    });

    await alert.present();
  }



  
  teacherLogoutAsStudent() { 
    this.returningToTeacherAccount()
    const tokenTeacher = window.localStorage.getItem('tokenTeacher');
    
    this.dataService.logout();///kill the sockets and session

    window.localStorage.setItem('token', tokenTeacher);
    window.localStorage.removeItem('tokenTeacher');
      this.handleToken();
    this.dataService.teacherIsLoggedInAsStudent = false; 
    if(window.localStorage.getItem("tokenTeacherAdmin")=="isTrue"){
      this.router.navigate([`/admin/users`]);
    }else{
    this.router.navigate([`/user-category`]);
  }
    setTimeout(() => {
      console.log(333333)
     /// window.location.reload(); 
    }, 1000)
   }

  
  async returningToTeacherAccount() {
    const loading = await this.loadingController.create({
      message: 'Returning to your account, please wait...',
      duration: 1000,
      spinner: 'bubbles'
    });
    await loading.present();
  }
  
  

}
