import {
  NgModule,
  Pipe,
  PipeTransform,
  isDevMode,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DataService } from './app.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from './share.module';
import { JoyrideModule } from 'ngx-joyride';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { MatomoModule } from 'ngx-matomo';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
    }),
    JoyrideModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    MatomoModule.forRoot({
      scriptUrl: 'https://probe.akaal.biz/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://probe.akaal.biz/matomo.php',
          siteId: 2
        }
      ],
      routeTracking: {
        enable: true
      }
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DataService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
