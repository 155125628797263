import { LoadingController, ModalController } from '@ionic/angular';
import { DataService } from './../../app.service';
import { Component, OnInit, Input } from '@angular/core';
import config from '../../../config';
import axios from 'axios';
import { CheckoutPage } from '../../modals/checkout/checkout.page';
import { Router } from '@angular/router';
import * as toastr from 'toastr';

@Component({
  selector: 'app-user-checkout',
  templateUrl: './user-checkout.page.html',
  styleUrls: ['./user-checkout.page.scss'],
})
export class UserCheckoutPage implements OnInit {
  @Input() id: string;
  @Input() course: any;
  @Input() courseList: any;
  @Input() step: any;

  loading: boolean = true;
  checkoutStep: any = 1;
  selectedCheckoutItem: any = {};

  activity: any;
  name: any = '';
  email: any = '';
  phone: any = '';
  card: any = null;
  isCheckout: any = false;
  isName: any = false;
  isEmail: any = false;
  isPhone: any = false;
  textError: any = '';
  cardData: any = null;
  isLoading: any = true;
  order: any = {};
  startCheckout: any = false;
  courseSelected: any = null;
  paymentType: any = '';
  categories: any = [];
  totalNeedPay: any = 0;
  constructor(
    public dataService: DataService,
    public loadingController: LoadingController,
    public modalController: ModalController,
    public router: Router,
  ) {
    this.name = this.dataService.currentUser.fullName;
    this.email = this.dataService.currentUser.email;
    this.phone = this.dataService.currentUser.phone;
  }

  ngOnInit() {
    console.log(this.id)
    console.log('course list', this.courseList)
    this.dataService.getCategory().then(() => {
      this.categories = JSON.parse(JSON.stringify(this.dataService.categories));
      if (this.courseList) {
        this.categories = this.categories.filter((item) => this.courseList.includes(item.id) && !item.public);
        this.categories.forEach(item => {
          if (item.fullPricing) {
            this.totalNeedPay += item.fullPricing;
          }
          if(this.step.customPrice){
            this.totalNeedPay = this.step.customPrice;
          }
        });
      }
      this.loading=false;
      if (this.id) {
        const index = this.categories.findIndex(item => item.id == this.id)
        if (index !== -1) {
          this.presentLoading(this.categories[index]);
        }
      }
    });
  }


  continuePayment() {
    this.startCheckout = true;
    this.getCard();
  }

  async presentLoading(item) {
    if (this.courseList && this.courseList.length > 1) {
      return;
    }
    this.selectedCheckoutItem = item;
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: `<b>${item.title}</b><br>Please wait, loading...`,
      duration: 1000
    });
    setTimeout(() => {
      this.checkoutStep = 3;
      if (this.course) {
        this.checkout('lesson', this.course);
      }
    }, 1500);
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  continue() {
    this.checkoutStep = 3;
  }

  continueLesson() {
    console.log(this.selectedCheckoutItem)
    this.checkoutStep = 5;
  }

  async checkout(type, course) {
    if (course) {
      this.courseSelected = course;
    } else {
      this.courseSelected = null;
    }
    this.paymentType = type;
    this.checkoutStep = 4;
    // const loading = await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: `<b>${this.selectedCheckoutItem.title}</b><br>Please wait while we process your order.`,
    //   duration: 2000
    // });
    // // setTimeout(() => {
    // //   this.modalController.dismiss();
    // //   window.alert('We have received your request. A sales rep will contact you soon.');

    // // }, 4000);
    // await loading.present();


    // const res = await axios.post(config.api.studentCreateActivity, {
    //   email: this.dataService.currentUser.email,
    //   type: 'Payment',
    //   title: `Request checkout from ${this.dataService.currentUser.email}`,
    //   description: `${this.dataService.currentUser.fullName} is requesting a course`,
    //   from: new Date(),
    //   categoryPricing: type,
    //   category: this.selectedCheckoutItem.id
    // });

    // res.data.Category = this.selectedCheckoutItem;
    // console.log(111111111111111111, res.data)
    // this.activity = res.data;
    this.startCheckout = true;
    await this.getCard();

    console.log(type);
  }

  async getCard() {
    const resp = await axios.get(config.api.getCustomerData);
    this.cardData = resp.data;
    this.isLoading = false;
    setTimeout(() => {
      this.initFormStripe();
    }, 1000);
    console.log(resp);
    console.log('activity', this.activity);
    if (this.paymentType === 'lesson') {
      this.order.total = this.courseSelected.price || 0;
    } else {
      this.order.total = this.selectedCheckoutItem.fullPricing || 0;
    }
    this.order.paymentType  = this.paymentType;
  }
  initFormStripe() {
    const elements = this.dataService.stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: '#222',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#222'
        },
        backgroundColor: '#fff',
        height: '100px',
        borderColor: '#ddd',
      },
      invalid: {
        color: '#222',
        iconColor: '#222'
      }
    };

    // Create an instance of the card Element.
    this.card = elements.create('card', { style });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element');
  }

  changeCard () {
    this.cardData = null;
  }

  checkoutCard(isListCourse) {
    const self = this;
    console.log(this.isCheckout);
    const dataPayload: any = {
      total: self.order.total,
      customPrice: self.order.customPrice,
      paymentType: self.order.paymentType,
      categoryId: self.selectedCheckoutItem.id,
      name: self.name,
      phone: self.phone,
      email: self.email,
    };
    if (this.courseSelected) {
      dataPayload.courseId = self.courseSelected.id;
    }

    if (isListCourse) {
      dataPayload.courseList = this.courseList;
      dataPayload.total = this.totalNeedPay;
      if(this.step.customPrice){ 
        this.totalNeedPay = dataPayload.customPrice
      }
      dataPayload.paymentType = 'full';
    }
    const handlePayment = async () => {
      const resp = await axios.post(config.api.checkoutCard, dataPayload);
      console.log('resp', resp);
      if (resp.data.message === 'success') {
        toastr.success(`Payment done!!!`);
        this.modalController.dismiss('done');
        this.router.navigate([`/user-category/`]);
      }
    };

    if (self.cardData) {
      if (this.isCheckout) {
        return;
      };
      this.textError = '';
      self.isCheckout = true;
      handlePayment();
    } else {
      if (!this.name) {
        this.isName = true;
        return;
      }
      if (!this.email) {
        this.isEmail = true;
        return;
      }
      if (!this.phone) {
        this.isPhone = true;
        return;
      }
      if (this.isCheckout) return;
      this.textError = '';
      this.isCheckout = true;
      this.isName = false;
      this.isEmail = false;
      this.isPhone = false;
      this.dataService.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card
        })
        .then( (result) => {
          console.log('result', result)
          if (result.error) {
            self.isCheckout = false;
            self.textError = result.error.message;
          } else if (result.paymentMethod) {
            dataPayload.payment_method = result.paymentMethod.id;
            handlePayment();
          }
        });
    }

  }
}
