<ion-app>




  <div class="loading-page fit horizontal layout center-justified center" *ngIf="loginService.loadingPage">
    <ion-spinner color="light" name="bubbles"></ion-spinner>
  </div>

  <ion-split-pane when="lg" [when]="dataService.getSplitPane()" contentId="main-content2"
    *ngIf="dataService.currentUser.id">

    <ion-menu contentId="main-content2" type="reveal" class="side-left" [ngClass]="{'hide-menu': isToggleMenu}">





      <ion-content>



        <ion-button size="large" color="dark" class="btn-menu  " (click)="toggleMenu()">
          <ion-icon slot="icon-only" name="menu-outline"></ion-icon>
        </ion-button>

        <div class="ion-no-border logo-app" style="margin-top:20px;margin-bottom:-40px" (click)="goToProfile()">
          <div class="ion-text-center" *ngIf="dataService.setting.loaded">
            <img style="width:100px;" src="../assets/images/gobc-logo.png" alt="" *ngIf="!dataService.setting.logo">
            <img [src]="dataService.setting.logo" alt="" style="width: 150px;" *ngIf="dataService.setting.logo">
          </div>

        </div>



        <div class="slide-left-profile" [hidden]="!dataService.applicationSettings[0].enabled">
          <div class="profile-logo pointer" (click)="goToProfile()">
            <app-avatar [data]="dataService.currentUser" type="slide-left"></app-avatar>
          </div>
          <div class="profile-name pointer horizontal layout center center-justified" (click)="goToProfile()">
            {{dataService.currentUser.fullName}} <ion-badge color="tertiary" class="ml-1"
              *ngIf="dataService.currentUser.role !== 'user'">{{dataService.currentUser.role}}</ion-badge>
          </div>
          <div class="profile-email pointer" (click)="goToProfile()">
            {{dataService.currentUser.email}}
          </div>
        </div>


        <div class="list-menu" *ngIf="dataService.teacherIsLoggedInAsStudent==true">

          <div class="ion-text-center">
            <ion-button *ngIf="!this.isToggleMenu" color="danger" (click)="loginService.teacherLogoutAsStudent()">
              Return to your account
            </ion-button>
            <ion-button *ngIf="this.isToggleMenu" color="danger" (click)="loginService.teacherLogoutAsStudent()">
              <ion-icon name="chevron-back"></ion-icon>
            </ion-button>
          </div>

        </div>


        <div class="list-menu" *ngIf="dataService.teacherIsLoggedInAsStudent==false">

          <ion-menu-toggle auto-hide="false" *ngIf="dataService.currentUser.role === 'admin'">
            <div class="menu-item horizontal layout center blue-13" routerDirection="root" routerLink="/setting"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-cog mr-4 ml-1"></i>
              <div class="flex">App Settings</div>
            </div>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" (click)="goToProfile()">
            <div class="menu-item horizontal layout center blue" routerDirection="root" routerLink="/profile"
              lines="none" detail="false" routerLinkActive="active">

              <i style="margin-left:-1px" class="fas fa-user-pen   mr-4 ml-1"></i>

              <div class="flex">My Profile</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.currentUser.role === 'user' || dataService.currentUser.role === 'teacher' || dataService.currentUser.role === 'school'">
            <div class="menu-item horizontal layout center green" routerDirection="root" routerLink="/user-category"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:-2px" class="fas  fa-graduation-cap mr-4 ml-1"></i>
              <div class="flex">My Courses</div>
            </div>
          </ion-menu-toggle>




          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[20].enabled">
            <div class="menu-item horizontal layout center blue-5" routerDirection="root" routerLink="/notification"
              lines="none" detail="false" routerLinkActive="active">

              <i class="fas fa-bell mr-4 ml-1"></i>

              <div class="flex">Notifications</div>
              <ion-badge color="danger" [ngClass]="{'hide-visible': !dataService.getNotificationCount()}">
                {{dataService.getNotificationCount()}}</ion-badge>
            </div>
          </ion-menu-toggle>


          <ion-menu-toggle *ngIf="dataService.applicationSettings[9].enabled && dataService.currentUser.role!=='user'"
            (click)="dataService.getOrderData();" routerLink="/orders" lines="none" detail="false"
            routerLinkActive="active" auto-hide="false">
            <div class="menu-item horizontal layout center blue-7">
              <i style="font-size:20px;margin-left:-2px;" class="fa-solid fa-cart-arrow-down mr-4 ml-1"></i>
              <div class="flex">Order Management</div>
              <ion-badge *ngIf="dataService.pendingOrders!==0  && dataService.currentUser.role!=='user'" color="danger">
                {{dataService.pendingOrders}}</ion-badge>
              <ion-badge *ngIf="dataService.pendingOrders==0   && dataService.currentUser.role!=='user'"
                color="success">Done <ion-icon name="checkmark-outline"></ion-icon>
              </ion-badge>

            </div>
          </ion-menu-toggle>


          <!-- <ion-menu-toggle *ngIf="dataService.applicationSettings[10].enabled" auto-hide="false">
            <div (click)="dataService.fetchFaqCount();" class="menu-item horizontal layout center blue-12"
              routerDirection="root" routerLink="/faq" lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:4px" class="fas fa-question mr-4 ml-1"></i>
              <div class="flex">FAQ
              </div>
              <ion-badge *ngIf="dataService.faqCount==0   && dataService.currentUser.role!=='user'" color="success">Done
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-badge>
              <ion-badge *ngIf="dataService.faqCount!==0" color="danger">{{dataService.faqCount}}
              </ion-badge>

            </div>
          </ion-menu-toggle> -->




          <ion-menu-toggle *ngIf="dataService.applicationSettings[10].enabled" auto-hide="false">
            <div (click)="dataService.fetchFaqCount();" class="menu-item horizontal layout center blue-12"
              routerDirection="root" routerLink="/support-tickets" lines="none" detail="false"
              routerLinkActive="active">
              <i style="margin-left:4px" class="fas fa-question mr-4 ml-1"></i>
              <div class="flex">Support Tickets
              </div>
              <ion-badge *ngIf="dataService.supportTicketUnread" color="danger">{{dataService.supportTicketUnread}}
              </ion-badge>

            </div>
          </ion-menu-toggle>




          <!-- 

          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[12].enabled">
            <div class="menu-item horizontal layout center blue-8" routerDirection="root" routerLink="/chats"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:-2px" class="fas fa-comments mr-4 ml-1"></i>
              <div class="flex">Classroom Chat</div>
              <ion-badge color="danger" [ngClass]="{'hide-visible': !dataService.getChatCount()}">
                {{dataService.getChatCount()}}</ion-badge>
            </div>
          </ion-menu-toggle> -->



          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.applicationSettings[8].enabled && dataService.currentUser.role!== 'user' ">
            <div class="menu-item horizontal layout center blue-3" routerDirection="root" routerLink="/admin/users"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:-2px" class="fas fa-users-gear mr-4 ml-1"></i>

              <div class="flex">User Accounts</div>
            </div>
          </ion-menu-toggle>


          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.applicationSettings[16].enabled && dataService.currentUser.role !== 'user'">
            <div class="menu-item horizontal layout center green" routerDirection="root" routerLink="/admin/courses"
              lines="none" detail="false" routerLinkActive="active">

              <i style="margin-left:-2px" class="fas  fa-graduation-cap mr-4 ml-1"></i>

              <div class="flex">Course Editor</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle *ngIf="dataService.applicationSettings[5].enabled" routerLink="/products" lines="none"
            detail="false" routerLinkActive="active" auto-hide="false">
            <div class="menu-item horizontal layout center blue-11">
              <i class="fas fa-list-tree  mr-4 ml-1"></i>
              <div class="flex">Product Manager</div>
            </div>
          </ion-menu-toggle>


          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.currentUser.role === 'school' || dataService.currentUser.role === 'teacher'">
            <div class="menu-item horizontal layout center blue" routerDirection="root" routerLink="/user-report"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-chart-line mr-4 ml-1"></i>
              <div class="flex">My Students</div>
            </div>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="dataService.applicationSettings[1].enabled" auto-hide="false">
            <div class="menu-item horizontal layout center blue-1" routerDirection="root" routerLink="/academy"
              lines="none" detail="false" routerLinkActive="active">
              <ion-icon class="mr-4 ml-1" name="folder-outline"></ion-icon>

              <div class="flex">Lessons</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[17].enabled ">
            <div class="menu-item horizontal layout center blue-2" routerDirection="root" routerLink="/quiz"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-file-signature mr-4 ml-1"></i>

              <div class="flex">Quiz Manager</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[17].enabled">
            <div class="menu-item horizontal layout center blue-6" routerDirection="root" routerLink="videos"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-tv-retro mr-4 ml-1"></i>

              <div class="flex">Video Manager</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[17].enabled">
            <div class="menu-item horizontal layout center green" routerDirection="root" routerLink="/admin/files"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left: 0px" class="fas fa-folder-arrow-down mr-4 ml-1"></i>
              <div class="flex">File Manager</div>
            </div>
          </ion-menu-toggle>




          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[18].enabled">
            <div class="menu-item horizontal layout center blue-9" routerDirection="root" routerLink="/glossary"
              lines="none" detail="false" routerLinkActive="active">

              <i style="margin-left: 2px" class="fas fa-solid fa-align-left mr-4 ml-1"></i>

              <div class="flex">Glossary</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[11].enabled">
            <div class="menu-item horizontal layout center blue-14" routerDirection="root" routerLink="/zoom"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left: -2px" class="fa fas fa-chalkboard-user mr-4 ml-1"></i>
              <div *ngIf="dataService.currentUser.role=='user'" class="flex">Join a Zoom Class</div>
              <div *ngIf="dataService.currentUser.role!=='user'" class="flex">Create/Join Zoom</div>
            </div>

          </ion-menu-toggle>

          <!-- <ion-menu-toggle auto-hide="false" *ngIf="dataService.currentUser.role === 'admin'">
            <div class="menu-item horizontal layout center blue-2" routerDirection="root" routerLink="/exam" lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-question-circle mr-4 ml-1"></i>
              
              <div class="flex">Exams Manager</div>
            </div>
          </ion-menu-toggle> -->





          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[21].enabled">
            <div class="menu-item horizontal layout center blue-4" routerDirection="root" routerLink="/user-assignment"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-file-signature mr-4 ml-1"></i>

              <div class="flex">Assignment Inbox</div>
            </div>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[19].enabled">
            <div class="menu-item horizontal layout center blue-8" routerDirection="root" routerLink="/admin/chats"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-comment mr-4 ml-1"></i>

              <div class="flex">Chat History</div>
            </div>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="dataService.applicationSettings[3].enabled" (click)="checkout()" auto-hide="false">
            <div class="menu-item horizontal layout center blue-11">
              <i class="fas fa-shopping-cart  mr-4 ml-1"></i>

              <div class="flex">Old Cart (discontinued)</div>
            </div>
          </ion-menu-toggle>




          <ion-menu-toggle *ngIf="dataService.applicationSettings[6].enabled" routerLink="/user-products" lines="none"
            detail="false" routerLinkActive="active" auto-hide="false">
            <div class="menu-item horizontal layout center blue-7">
              <i class="fas fa-shopping-cart mr-4 ml-1"></i>
              <div class="flex">GOBC Shop</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle (click)="openCheckout()" *ngIf="dataService.order.products.length" lines="none"
            detail="false" routerLinkActive="active" auto-hide="false">
            <div class="menu-item horizontal layout center blue-7">
              <i class="fas fa-solid fa-basket-shopping-simple  mr-4 ml-1"></i>
              <div class="flex">View Cart</div>
              <ion-badge color="danger" slot="end">{{dataService.order.products.length}} added</ion-badge>
            </div>
          </ion-menu-toggle>




          <ion-menu-toggle [hidden]="true" auto-hide="false"
            *ngIf="dataService.currentUser.role == 'support' || dataService.currentUser.role == 'admin'">
            <div class="menu-item horizontal layout center blue-7" routerDirection="root"
              routerLink="/user/payment-history" lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:-2px" class="fas fa-shopping-cart mr-4 ml-1"></i>

              <div class="flex">Payment History</div>
            </div>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.applicationSettings[14].enabled && dataService.adminSetting.dropboxKey">
            <div class="menu-item horizontal layout center blue-14" routerDirection="root" routerLink="/dropbox-folder"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:1px" class="fas  fa-download   mr-4 ml-1"></i>

              <div class="flex">Dropbox</div>
            </div>
          </ion-menu-toggle>


          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[13].enabled">
            <div class="menu-item horizontal layout center blue-9" routerDirection="root" routerLink="/admin/activities"
              lines="none" detail="false" routerLinkActive="active">
              <i style="margin-left:0px" class="fas fa-ballot-check mr-4 ml-1"></i>

              <div class="flex">Activities</div>
            </div>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[2].enabled">
            <div class="menu-item horizontal layout center blue-9" routerDirection="root" routerLink="/email"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-envelope mr-4 ml-1"></i>

              <div class="flex">Email</div>
            </div>
          </ion-menu-toggle>


          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[15].enabled">
            <div class="menu-item horizontal layout center blue-14" routerDirection="root" routerLink="/admin/support"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-phone-alt mr-4 ml-1"></i>

              <div class="flex">Admin Support</div>
            </div>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false"
            *ngIf="dataService.applicationSettings[22].enabled && dataService.currentUser.role !== 'user'">
            <div class="menu-item horizontal layout center blue-14" routerDirection="root" routerLink="/brokerage"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-address-card mr-4 ml-1"></i>
              <div class="flex">Brokerage Manager

                <ion-badge color="danger" slot="end">0 Unread</ion-badge>
              </div>
            </div>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="dataService.applicationSettings[23].enabled">
            <div class="menu-item horizontal layout center blue-14" routerDirection="root" routerLink="/brokerage-user"
              lines="none" detail="false" routerLinkActive="active">
              <i class="fas fa-search mr-4 ml-1"></i>
              <div class="flex">Find a Brokerage</div>
            </div>
          </ion-menu-toggle>



          <ion-menu-toggle auto-hide="false" (click)="loginService.logout()">
            <div class="menu-item horizontal layout center blue-10">
              <i class="fas fa-sign-out-alt mr-4 ml-1"></i>
              <div class="flex">Log out</div>
            </div>
          </ion-menu-toggle>



        </div>
        <!-- <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list> -->
        <ion-footer class="menu-footer">

          <div class="menu-bottom" *ngIf="!dataService.teacherIsLoggedInAsStudent">
            <ion-menu-toggle auto-hide="false">
              <div class="menu-item-bottom" routerDirection="root" routerLink="/about-us" lines="none" detail="false"
                routerLinkActive="active">
                About Us
              </div>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false" routerDirection="root" routerLink="/privacy" lines="none" detail="false"
              routerLinkActive="active">
              <div class="menu-item-bottom">
                Privacy
              </div>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false" routerDirection="root" routerLink="/terms" lines="none" detail="false"
              routerLinkActive="active">
              <div class="menu-item-bottom">
                Terms
              </div>
            </ion-menu-toggle>
            <span style="margin-left:10px;font-size:12px; margin-top: auto; color:gray; opacity:0.75;" *ngIf="release">
              {{ release }}
            </span>
          </div>
        </ion-footer>

      </ion-content>


    </ion-menu>

    <ion-router-outlet id="main-content2"></ion-router-outlet>
  </ion-split-pane>



  <!--LOGIN NEW-->
  <div class="loginContainer" *ngIf="!dataService.currentUser.id">


    <div class="container" [class]="signUpClass" id="container">
      <!--signup-->
      <div class="form-container sign-up-container">

        <form autoComplete="off" style="padding:0px;margin:0px;" (ngSubmit)="loginService.submitSignup()">
          <h1>Create Account</h1>



          <ion-item>
            <ion-label position="floating">
              Email Address
            </ion-label>
            <ion-input [(ngModel)]="user.email" required maxlength="100" name="email" autoComplete="off"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">
              Password
            </ion-label>
            <ion-input [(ngModel)]="user.password" type="password" required maxlength="100" name="password"
              autoComplete="off"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">
              Full name
            </ion-label>
            <ion-input [(ngModel)]="user.fullName" required maxlength="100" name="fullName"></ion-input>
          </ion-item>



          <ion-item>
            <ion-label position="floating">
              Mobile phone
            </ion-label>
            <ion-input [(ngModel)]="user.phone" required maxlength="100" name="phone"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Account Type</ion-label>
            <ion-select placeholder="Select your account type" [(ngModel)]="user.accounttype" name="accounttype">
              <ion-select-option [value]="courseType.type"
                *ngFor="let courseType of dataService.setting.coursesType; let i = index">{{courseType.name}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <input type="submit" className="submit-enter" style="position: fixed;
        visibility: hidden;
        left: -1000px;" />

          <button>Signup
            <i class="fas fa-spinner fa-spin ml-1" *ngIf="loginService.loadingLogin"></i>
          </button>
          <button id="signUp" class="showLogin" (click)="$event.preventDefault();signUp('');">Return to login</button>

          <p class="text-center text-error" *ngIf="loginService.formError">{{loginService.formError}}</p>
        </form>
      </div>

      <!--signin-->
      <div class="form-container sign-in-container">
        <form (ngSubmit)="loginService.submitLogin()" *ngIf="!loginService.show2FA">
          <div class="ion-text-center">
            <img for="chk" aria-hidden="true" style="max-width:200px;margin-top:0px"
              src="../assets/images/gobc-logo.png" height="64" alt="" *ngIf="!dataService.setting.logoDefault">
            <img style="max-width:200px;margin-top:25px" [src]="dataService.setting.logoDefault" alt="" height="64"
              *ngIf="dataService.setting.logoDefault">
            <label style="margin-top:30px"> </label>
          </div>

          <input height="40" type="email" placeholder="email" [(ngModel)]="user.email" required maxlength="100"
            name="email" autoComplete="off">
          <input height="40" type="password" placeholder="password" [(ngModel)]="user.password" required maxlength="100"
            name="password" autoComplete="off">
          <button type="submit"><span *ngIf="!loginService.loadingLogin">Login</span> <i
              class="fas fa-spinner fa-spin ml-1" *ngIf="loginService.loadingLogin"></i></button>


          <p class="text-center text-error" *ngIf="loginService.formError && user.email">
            {{loginService.formError}}<br><br>Please try again or:


            <ion-button style="margin-top:20px" color="danger" size="small" (click)="sendResetCode();">Reset Password <i
                class="fas fa-spin fa-spinner" *ngIf="loadingReset"></i></ion-button>
          </p>

          <!-- <button class="showSignup" id="signUp2" (click)="$event.preventDefault();signUp('right-panel-active');">Sign Up</button> -->
          <a href="https://www.gobcrealestate.com/gobc-online-courses/" target="_blank"
            class="showSignup signup-link-button">Sign Up</a>



        </form>



        <!--forgot passowrd-->

        <!--verify account-->
        <div class="login" *ngIf="loginService.show2FA">
          <form class="form-signin-signup" (ngSubmit)="loginService.verifyCode()">
            <div class="logo text-center" *ngIf="dataService.setting.loaded">
              <img src="../assets/images/gobc-logo.png" height="64" alt="" *ngIf="!dataService.setting.logoDefault">
              <img [src]="dataService.setting.logoDefault" alt="" height="64" *ngIf="dataService.setting.logoDefault">
            </div>
            <h2 *ngIf="page === 'signup'">Verify sms<br><br></h2>
            <div class="group-input">
              <div class="label-group-input">

              </div>
              <div class="input-group-input">
                <input placeholder="Type the SMS code" type="text" [(ngModel)]="loginService.code" required
                  maxlength="6" name="code">
              </div>
            </div>

            <div class="button-submit">
              <ion-button color="success" expand="block" type="submit">
                Verify SMS Code
                <i class="fas fa-spinner fa-spin ml-1" *ngIf="loginService.loadingLogin"></i>
              </ion-button>
              <p class="text-center text-error" *ngIf="loginService.formError && loginService.code"></p>
              <div class="ion-text-center">
                <ion-button size="small" color="tertiary"
                  (click)="loginService.show2FA=false;loginService.formError=null">
                  Back to Login
                </ion-button>
              </div>
            </div>
          </form>
        </div>

      </div>

      <!--sidebar cool slider-->
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>Aready got an account?</h1>
            <p>To keep connected please login with your personal info</p>
            <button class="ghost" id="signIn" (click)="signUp('');">Login</button>


          </div>
          <div class="overlay-panel overlay-right">
            <h1>3-Day FREE Trial!</h1>
            <p>Create a new account and get started on your new journey!</p>
            <!-- <button class="ghost" id="signUp" (click)="signUp('right-panel-active');">Sign Up</button> -->
            <a href="https://www.gobcrealestate.com/gobc-online-courses/" target="_blank"
              class="ghost signup-link-button">Sign Up</a>
          </div>
        </div>
      </div>
    </div>


  </div>







</ion-app>