import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SpeedTestComponent } from './speed-test.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule],
  declarations: [SpeedTestComponent],
  exports: [SpeedTestComponent]
})
export class SpeedTestComponentModule {}
