import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UserListComponent } from './user-list.component';
import { AvatarComponentModule } from '../../components/avatar/avatar.module';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, AvatarComponentModule],
  declarations: [UserListComponent],
  exports: [UserListComponent]
})
export class UserListComponentModule {}
