import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AlertComponent } from './alert.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule],
  declarations: [AlertComponent],
  exports: [AlertComponent]
})
export class AlertComponentModule {}
