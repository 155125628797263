import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VideoComponent } from './video.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule],
  declarations: [VideoComponent],
  exports: [VideoComponent]
})
export class VideoComponentModule {}
