const url = '';
const release = 'prod-1.4.4';
export const environment = {
  production: true,
  url: url,
 //   url: 'https://srv02-api.mygobc.ca',
//    url: 'https://mygobc.andale.vn',
//    url: 'https://training-api.onlinegobc.com',
//    url: 'http://testing',
    onesignalId: '71877283-51cd-4701-b737-9dbbffb8ab97',
    onesignalSafariId: 'web.onesignal.auto.38b1a4de-a361-440e-ae28-b71c05790af2',
    dropboxRedirect:url+'/dropbox',
    zoomRedirect: url+'/auth-zoom',
    googleRedirect: url+'/auth-google',
    host: url,
    supportNumber: '+14132777027',
    release,
    sentry: {
      tracesSampleRate: 0.1,
      release: release,
      dsn: 'https://4ba1121537dcf32d15c30d0adc390968@o4504679835566080.ingest.us.sentry.io/4507411859636224',
    }
};
