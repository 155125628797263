<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title [hidden]="checkoutStep !== 1">Proceed to checkout</ion-title> 
    <ion-title [hidden]="checkoutStep !== 3">Choose a plan</ion-title>
    <ion-title [hidden]="checkoutStep !== 5">Select a single lesson</ion-title>
    <ion-title [hidden]="checkoutStep !== 4">Finalize your payment</ion-title>
    <img src="assets/stripe.png" height="40" slot="end" />
  </ion-toolbar>
</ion-header>

<ion-content>

  
<ion-card>
  <ion-progress-bar color="tertiary" value="0.1" [hidden]="checkoutStep !== 1">Select a course</ion-progress-bar> 
  <ion-progress-bar color="tertiary" value="0.4" [hidden]="checkoutStep !== 3">Choose a plan</ion-progress-bar>
  <ion-progress-bar color="tertiary" value="0.7" [hidden]="checkoutStep !== 5">Finalize payment</ion-progress-bar>
  <ion-progress-bar color="tertiary" value="1" [hidden]="checkoutStep !== 4">Finalize payment</ion-progress-bar>
</ion-card>

  <div [hidden]="startCheckout">

   

    <ion-list [hidden]="checkoutStep !== 1">
      
    


      
      <ion-button [disabled]="this.loading" size="large" color="success" expand="block" (click)="continuePayment()" *ngIf="courseList && courseList.length > 1">
        Continue Payment  ${{totalNeedPay}} 
      </ion-button>

      <ion-item-divider>
        <ion-label>Included in this purchase:</ion-label>
      </ion-item-divider>
      
      <ion-item *ngIf="this.loading">
        <ion-label>Loading...</ion-label> 
      </ion-item>
      
      <ion-item (click)="presentLoading(item)" button   *ngFor="let item of categories"
        [hidden]="item.public">
        <ion-label>{{item.title | titlecase}}<br>
           

        </ion-label>
         
      </ion-item>


    </ion-list>

    
    <h2  [hidden]="checkoutStep !== 5" class="ion-text-center"> Which lesson are you upgrading? 
    </h2>
    <ion-list [hidden]="checkoutStep !== 5">
      <div >

        
        <ion-item *ngFor="let item of selectedCheckoutItem.coursesData"
        [hidden]="item.public" (click)="checkout('lesson', item)" button detail="true">
  
          <ion-col size="9">
            <ion-label>{{item.title | titlecase}} </ion-label>
          </ion-col>

          <ion-col size="3">
            <div class="ion-text-right" color="tertiary"    >${{item.price || "FREE"}}</div>
          
          </ion-col>
        
        </ion-item>
        
          
       
         
      </div>
    </ion-list> 


    <div [hidden]="checkoutStep !== 3">

      <div>
        <h2 class="ion-text-center"> Would you like to upgrade to the full course or a single lesson? 
        </h2>

        <ion-list [hidden]="checkoutStep !== 3">
          <ion-item button detail="true" (click)="continueLesson()">Single Lessons</ion-item>
          <ion-item button detail="true"  (click)="checkout('full', null)" [disabled]="!selectedCheckoutItem.fullPricing">Full Course (${{selectedCheckoutItem.fullPricing || "FREE"}})</ion-item>
        </ion-list>
        
      </div>

    </div>
  </div>



  <div [hidden]="!startCheckout">

    
    <h2 [hidden]="!startCheckout" class="ion-text-center"> Complete your payment for instant access
      <span *ngIf="paymentType === 'lesson'">single lesson: {{courseSelected.title}}</span>
      <span *ngIf="paymentType === 'full'">full course: {{selectedCheckoutItem.title}}</span> 
    </h2>

    <div class="ion-padding custom-skeleton" *ngIf="isLoading">

      <div class="ion-text-center"><img src="assets/stripe.png" height="150" slot="end" /></div>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </div>

    <div class="padding" *ngIf="!isLoading">
      <div [hidden]="cardData">
        <ion-item *ngIf="!name">
          <ion-label position="stacked">Name</ion-label>
          <ion-input placeholder="Jane Doe" [(ngModel)]="name"></ion-input>
        </ion-item>
        <ion-item *ngIf="!email">
          <ion-label position="stacked">Email</ion-label>
          <ion-input type="email" placeholder="janedoe@gmail.com" [(ngModel)]="email"></ion-input>
        </ion-item>
        <ion-item *ngIf="!phone">
          <ion-label position="stacked">Phone</ion-label>
          <ion-input type="tel" placeholder="(941) 555-0123" [(ngModel)]="phone"></ion-input>
        </ion-item>

        <div class="row">
          <div id="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
        </div>
      </div>

      <div class="card-data" *ngIf="cardData">

        <div class="ion-text-center"><img src="assets/stripe.png" height="150" slot="end" /></div>

        <ion-card>
          <ion-card-header>
            <ion-card-title><span class="card-type">{{cardData.brand}}</span> - **** **** **** {{cardData.last4}}
            </ion-card-title>
            <ion-card-subtitle><span class="card-name">{{dataService.currentUser.fullname}}</span></ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <div class="ion-text-center">
          <ion-button size="small" color="medium" (click)="changeCard()" class="add-other-card">Add other card
          </ion-button>

        </div>
      </div>

      <div id="card-errors" *ngIf="textError">{{textError}}</div>

    </div>

    <div class="padding"> 

      <ion-button size="large" [disabled]="isCheckout" color="success" expand="block" (click)="checkoutCard(null)" *ngIf="!courseList || courseList.length < 2">
        Continue to Checkout (${{order.total}})   
        <ion-spinner *ngIf="isCheckout" class="spinner-login">
        </ion-spinner>
      </ion-button>
      <ion-button size="large"  [disabled]="isCheckout || isLoading"  color="success" expand="block" (click)="checkoutCard(true)" *ngIf="courseList && courseList.length > 1">
        Complete Payment (${{totalNeedPay | number : '1.0-2'}})
        <ion-spinner *ngIf="isCheckout" class="spinner-login">
        </ion-spinner>
      </ion-button>
    </div>
  </div>


</ion-content>