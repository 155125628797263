import { NgModule } from '@angular/core';
import { AvatarComponentModule } from './components/avatar/avatar.module';
import { VideoComponentModule } from './components/video/video.module';
import { SpeedTestComponentModule } from './components/speed-test/speed-test.module';
import { AlertComponentModule } from './components/alert/alert.module';
import { PaginationComponentModule } from './components/pagination/pagination.module';
import { UserListComponentModule } from './components/user-list/user-list.module';
import { ChatComponentModule } from './components/chat/chat.module';
import { ChatCategoryComponentModule } from './components/chat-category/chat-category.module';
import { DashboardComponentModule } from './components/dashboard/dashboard.module';
import { MeterComponentModule } from './components/meter/meter.module';
import { SafeHtml } from './services/safeHtml';

@NgModule({
  declarations: [SafeHtml],
  imports: [
  // Leave this blank
  ],
  exports: [
    AvatarComponentModule,
    UserListComponentModule,
    ChatComponentModule,
    ChatCategoryComponentModule,
    PaginationComponentModule,
    AlertComponentModule,
    DashboardComponentModule,
    MeterComponentModule,
    SafeHtml,
    VideoComponentModule,
    SpeedTestComponentModule
  ],
})
export class ShareModule {}
