import config from './config';
declare const window: any;

let socket;

export default {
  init: async (cb, userId) => {

	let tries = 50;
	while(--tries > 0 && !window.io) {
		await new Promise(resolve => setTimeout(resolve, 100));
	}

    socket = window.io(config.url, {
      path: '/api/socket.io-client',
      query: {
        user: userId,
      },
      transports: ['websocket']
    });
    socket.on('connect', () => {
      cb(socket.id);
    });
  },

  subscribe: (channel, callback) => {
	if(!socket) {
		return
	}
    socket.on(channel, (doc) => callback(doc));
  },

  unSubscribe: (channel) => {
    if (!socket) {
      return;
    }
    socket.removeAllListeners(channel);
  },

  getSocketId: () => socket ? socket.id : undefined,

  close: () => {
    if (!socket) {
      return;
    }
    socket.close();
    socket = undefined
  },

  getSocket: () => socket,
};
