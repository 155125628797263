import * as moment from 'moment';

const getParamValue = (param, url) => {
  if (!url) {
    url = window.location.href;
  }
  param = param.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const displayTime = (seconds) => {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;

  return [hours, minutes, seconds % 60].map(format).join(':');
};


const hashCode = (str) => {
  let hash: any = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i) => {
  // eslint-disable-next-line
  const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

const randomeColorByStr = (str = 'njdksksk') => intToRGB(hashCode(str));

const dateTime = (value) => {
  let date = new Date();
  if (value) {
    date = new Date(value);
  }
  const today = moment(new Date()).format('DD-MM-YYYY');
  const fromDay = moment(date).format('DD-MM-YYYY');
  if (today !== fromDay) {
    return moment(date).format('MM-DD hh:mm A');
  }
  return moment(date).format('hh:mm A');
};

const generateRandomString = (length = 10) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    // eslint-disable-next-line no-bitwise
    const hrs = ~~(duration / 3600);
    // eslint-disable-next-line no-bitwise
    const mins = ~~((duration % 3600) / 60);
    // eslint-disable-next-line no-bitwise
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
};

export default {
  getParamValue,
  displayTime,
  randomeColorByStr,
  dateTime,
  generateRandomString,
  fancyTimeFormat,
};
